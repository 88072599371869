import React, { useEffect, useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Form, Input, Select, Button, Spin, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@ant-design/icons";
import TitleTooltip from "../../../components/TitleTooltip/TitleTooltip";
import api from "../../../requests/api";
const { Item } = Form;
const { Option } = Select;

function CampaignForm({ selectedCampaign, templateList, reloadCb }) {
    const history = useHistory();
    const { t } = useTranslation();
    const [customers, setCustomers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [customersGroups, setCustomersGroups] = useState([]);
    const [selectedCustomersGroups, setSelectedCustomersGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [customersPaginatorPage, setCustomersPaginatorPage] = useState(0);
    const [customersPaginatorHasNext, setCustomersPaginatorHasNext] = useState(false);
    const [customerSearchText, setCustomerSearchText] = useState(null);
    const [selected, setSelected] = useState([
        { name: "name", value: "" },
        { name: "template_id", value: [] },
        { name: "customers_id", value: [] },
        { name: "customer_groups_id", value: [] },
        { name: "cost", value: 0 },
    ]);
    const goBack = function () {
        reloadCb();
        history.push("/dashboard/outbound/campaigns");
    };
    const sendForm = (data) => {
        if (selectedCampaign && selectedCampaign.id) {
            const updated = { ...data, id: selectedCampaign.id };
            api.outbound()
                .updateWhatsappCampaign(updated)
                .then((res) => {
                    reloadCb();
                    message.success("Campaign updated");
                    history.push("/dashboard/outbound/campaigns");
                });
        } else {
            api.outbound()
                .createWhatsappCampaign(data)
                .then((res) => {
                    reloadCb();
                    message.success("Campaign created");
                    history.push("/dashboard/outbound/campaigns");
                })
                .catch((err) => {
                    if (err.response?.data?.error?.key === "BAD_REQUEST_BODY") {
                        message.error(t("outbound.campaignObj.form.campaignFormBodyError"));
                    }
                });
        }
    };
    const handleTemplate = (selected) => {
        console.log(selected);
    };
    const searchCustomer = (text) => {
        setCustomerSearchText(text);
        setCustomersPaginatorPage(0);
    };
    function handleCustomersPopupScroll(e) {
        e.persist();
        let target = e.target;
        if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
            if (customersPaginatorHasNext) {
                setCustomersPaginatorPage((customersPaginatorPage) => customersPaginatorPage + 1);
            }
        }
    }

    useEffect(() => {
        setIsLoading(true);
        api.customers()
            .list(customersPaginatorPage, customerSearchText, null, null, true)
            .then((res) => {
                if (customersPaginatorPage === 0) {
                    setCustomers(res.data.customers);
                } else {
                    setCustomers((customers) => [...customers, ...res.data.customers]);
                }
                setCustomersPaginatorHasNext(res.data.paginator.hasNext);
                setIsLoading(false);
            });
    }, [customersPaginatorPage, customerSearchText]);

    useEffect(() => {
        api.customers()
            .groups()
            .then((res) => {
                setCustomersGroups(res.data.customerGroups);
            });
        if (selectedCampaign) {
            const customersCampaignId = selectedCampaign.customers.map((c) => c.id);
            const customersInCampaign = customers.filter((c) => customersCampaignId.includes(c.id));
            setSelectedCustomers(customersInCampaign);
            setSelectedCustomersGroups(selectedCampaign.customerGroups);
            setSelected([
                { name: "name", value: selectedCampaign.name },
                { name: "template_id", value: selectedCampaign.template.id },
                {
                    name: "customers_id",
                    value: customersInCampaign.map((c) => c.id),
                },
                {
                    name: "customer_groups_id",
                    value: selectedCampaign.customerGroups
                        ? selectedCampaign.customerGroups.map((g) => g.id)
                        : [],
                },
            ]);
        }
    }, [selectedCampaign, customers]);

    return (
        <Spin spinning={isLoading}>
            <div className="opt-view" style={{ padding: 0 }}>
                <div class="opt-card" style={{ maxWidth: "40%" }}>
                    <Form fields={selected} layout="vertical" onFinish={sendForm}>
                        <Item
                            label={t("outbound.campaignObj.form.name")}
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: t("outbound.campaignObj.form.nameRule"),
                                },
                            ]}
                        >
                            <Input
                                size="large"
                                placeholder={t("outbound.campaignObj.form.namePlaceholder")}
                                disabled={selectedCampaign}
                            />
                        </Item>
                        <Item
                            label={
                                <>
                                    <span style={{ marginRight: "5px" }}>
                                        {t("outbound.campaignObj.form.templates")}
                                    </span>{" "}
                                    <TitleTooltip
                                        content={t("outbound.campaignObj.form.templatesMessage")}
                                    />{" "}
                                </>
                            }
                            name="template_id"
                            required={true}
                        >
                            <Select
                                placeholder={t("outbound.campaignObj.form.templatesPlaceholder")}
                                onChange={handleTemplate}
                                disabled={selectedCampaign}
                            >
                                {templateList.map(
                                    (item) =>
                                        item.status === "APPROVED" && (
                                            <Option value={item.id}>{item.name}</Option>
                                        )
                                )}
                            </Select>
                        </Item>
                        <Item
                            label={t("outbound.campaignObj.form.customerGroups")}
                            name="customer_groups_id"
                            required={false}
                        >
                            <Select
                                disabled={selectedCampaign}
                                placeholder={t(
                                    "outbound.campaignObj.form.customerGroupsPlaceholder"
                                )}
                                allowClear
                                filterOption={(inputValue, option) =>
                                    option.props.children
                                        .toLowerCase()
                                        .indexOf(inputValue.toLowerCase()) >= 0
                                }
                                mode="multiple"
                                defaultValue={selectedCustomersGroups}
                            >
                                {customersGroups &&
                                    customersGroups.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Item>
                        {selectedCampaign ? (
                            <Item>
                                <span>
                                    {t("outbound.campaignObj.form.amountSelectedCustomers")}:{" "}
                                </span>
                                <NavLink
                                    to={{
                                        pathname: `/dashboard/customers/`,
                                        state: {
                                            customersIds: selectedCampaign.customers.map(
                                                (c) => c.id
                                            ),
                                        },
                                    }}
                                >
                                    {selectedCampaign.customers.length}
                                    <Space>
                                        <div style={{ marginLeft: "8px" }}>
                                            <Button
                                                shape="circle"
                                                size="small"
                                                icon={
                                                    <SearchOutlined
                                                        style={{
                                                            position: "relative",
                                                            top: "2px",
                                                        }}
                                                    />
                                                }
                                            ></Button>
                                        </div>
                                    </Space>
                                </NavLink>
                            </Item>
                        ) : (
                            <>
                                <Item
                                    label={t("outbound.campaignObj.form.customers")}
                                    name="customers_id"
                                    required={false}
                                >
                                    <Select
                                        disabled={selectedCampaign}
                                        placeholder={t(
                                            "outbound.campaignObj.form.customersPlaceholder"
                                        )}
                                        showSearch
                                        filterOption={false}
                                        mode="multiple"
                                        onSearch={searchCustomer}
                                        defaultValue={selectedCustomers}
                                        onPopupScroll={handleCustomersPopupScroll}
                                    >
                                        {customers &&
                                            customers.map((item) => (
                                                <Option value={item.id}>
                                                    {item.fullName} {item.phone}
                                                </Option>
                                            ))}
                                    </Select>
                                </Item>
                            </>
                        )}
                        <Space style={{ width: "100%", justifyContent: "flex-end" }}>
                            {selectedCampaign && selectedCampaign.status === "SENT" ? (
                                <>
                                    <Button
                                        onClick={goBack}
                                        type="warning"
                                        style={{ marginLeft: "5px" }}
                                    >
                                        {t("outbound.campaignObj.form.buttonBack")}
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        onClick={goBack}
                                        type="warning"
                                        style={{ marginLeft: "5px" }}
                                    >
                                        {t("outbound.campaignObj.form.buttonCancel")}
                                    </Button>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{ marginLeft: "5px" }}
                                    >
                                        {t("outbound.campaignObj.form.buttonCreate")}
                                    </Button>
                                </>
                            )}
                        </Space>
                    </Form>
                </div>
            </div>
        </Spin>
    );
}
export default CampaignForm;
