import { Button, DatePicker, Modal, Select, Space, Spin, Tag, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Search from "antd/es/input/Search";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { AppContext } from "../../../components/AppContext";
import LoadMore from "../../../components/LoadMore/LoadMore";
import Loader from "../../../components/Loader/Loader";
import TitleTooltip from "../../../components/TitleTooltip/TitleTooltip";
import api from "../../../requests/api";
import MonitoringTable from "./MonitoringTable";
import ACTIONS from "./filters/actions";
import useFilter from "./filters/useFilter";
import { SearchOutlined } from "@ant-design/icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";

const { RangePicker } = DatePicker;
const { Option } = Select;

const agentUrl = process.env?.REACT_APP_AGENT_URL;

function MonitoringList() {
    const appContext = useContext(AppContext);
    const location = useLocation();
    const [chats, setChats] = useState(null);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [initialLoadIsLoading, setInitialLoadIsLoading] = useState(true);
    const [selectLoader, setSelectLoader] = useState(false);
    const [agents, setAgents] = useState([]);
    const [customersList, setCustomersList] = useState([]);
    const searchTypingTimeoutRef = useRef(null);
    const customerSearchTypingTimeoutRef = useRef(null);
    const [searchInputVal, setSearchInputVal] = useState(null);
    const [dateFormat, setDateFormat] = useState("DD/MM/YYYY");
    const [dates, setDates] = useState([moment().subtract(1, "week"), moment()]);
    const { t } = useTranslation();
    const [groups, setGroups] = useState([]);
    const [filterByGroupsModalIsVisible, setFilterByGroupsModalIsVisible] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [datesError, setDatesError] = useState(false);
    const defaultState = {
        status: null,
        customerChannel: null,
        customerId: null,
        customerStatus: null,
        ownedByAgentType: null,
        participatingAgentId: null,
        startDate: moment().subtract(1, "week"),
        endDate: moment(),
        tagsId: [],
        paginator: {
            page: 0,
        },
        searchText: null,
    };

    const [filterState, filterDispatch] = useFilter(
        "opt-conv-filter",
        defaultState,
        location.state && location.state.filters
    );
    const [usersPaginatorPage, setUsersPaginatorPage] = useState(0);
    const [usersPaginatorHasNext, setUsersPaginatorHasNext] = useState(false);
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("monitoringList.createdOnTitle"),
            dataIndex: "createdOn",
            key: "createdOn",
            render: (data) => moment(data).local().format("DD/MM/YY hh:mm"),
        },
        {
            title: t("monitoringList.lastEndUserActivityTitle"),
            dataIndex: "lastEndUserActivity",
            key: "lastEndUserActivity",
            render: (data) => moment(data).local().format("DD/MM/YY hh:mm"),
        },
        {
            title: t("monitoringList.customerNameTitle"),
            dataIndex: "customer",
            key: "customerName",
            render: (data) => (data.fullName ? data.fullName : t("notRegistered")),
        },
        {
            title: t("monitoringList.phoneTitle"),
            dataIndex: "customer",
            key: "customerPhone",
            render: (data) => (data.phone ? data.phone : t("notRegistered")),
        },
        {
            title: t("monitoringList.customerChannelTitle"),
            dataIndex: "customerChannel",
            key: "customerChannel",
            filters: [
                {
                    text: t("monitoringList.customerChannel.webChat"),
                    value: "OPTIWE_CHAT",
                },
                {
                    text: t("monitoringList.customerChannel.whatsapp"),
                    value: "WHATSAPP",
                },
                {
                    text: t("monitoringList.customerChannel.facebook"),
                    value: "FACEBOOK",
                },
                {
                    text: t("monitoringList.customerChannel.instagram"),
                    value: "INSTAGRAM",
                },
                {
                    text: t("monitoringList.customerChannel.otherIntegration"),
                    value: "UI_INTEGRATION",
                },
                {
                    text: t("monitoringList.customerChannel.facebookComment"),
                    value: "FB_COMMENT",
                },
            ],
            filterMultiple: false,
            defaultFilteredValue: null,
            filteredValue: filterState.customerChannel,
            render: (data) => parseChannel(data.type),
        },
        {
            title: t("monitoringList.assignToTitle"),
            dataIndex: "ownedByAgentType",
            key: "ownedByAgentType",
            filters: [
                {
                    text: t("monitoringList.agents.none"),
                    value: "UNASSIGNED",
                },
                {
                    text: t("monitoringList.agents.humanAgent"),
                    value: "HUMAN_AGENTS",
                },
                {
                    text: t("monitoringList.agents.chatBot"),
                    value: "AI_AGENTS",
                },
            ],
            filterMultiple: false,
            defaultFilteredValue: null,
            filteredValue: filterState.ownedByAgentType,
            render: (data) => {
                if (!data) {
                    return (
                        <Tag color={"red"} key={1}>
                            {t("monitoringList.agents.none")}
                        </Tag>
                    );
                } else if (data === "ASSISTANT") {
                    return (
                        <Tag color={"purple"} key={1}>
                            {t("monitoringList.agents.humanAgent")}
                        </Tag>
                    );
                } else if (data.includes("CHAT_BOT")) {
                    return (
                        <Tag color={"volcano"} key={1}>
                            {t("monitoringList.agents.chatBot")}
                        </Tag>
                    );
                }
            },
        },
        {
            title: t("monitoringList.statusTitle"),
            dataIndex: "status",
            key: "status",
            filters: [
                {
                    text: t("monitoringList.conversationStatus.active"),
                    value: "ACTIVE",
                },
                {
                    text: t("monitoringList.conversationStatus.ended"),
                    value: "ENDED",
                },
            ],
            filterMultiple: false,
            defaultFilteredValue: null,
            filteredValue: filterState.status,
            render: (text, record) => (
                <Space size="middle" key="1">
                    <Tag color={record.status === "ENDED" ? "red" : "green"}>{record.status}</Tag>
                </Space>
            ),
        },
        {
            title: t("monitoringList.actionTitle"),
            key: "action",
            render: (text, record) => (
                <Space size="middle" key="2">
                    <button className="opt-icon-button">
                        <a href={`${agentUrl}/chat/${record.id}`} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faComments} />
                        </a>
                    </button>

                    <button className="opt-icon-button">
                        <NavLink to={`/dashboard/monitoring/${record.id}`}>
                            <SearchOutlined />
                        </NavLink>
                    </button>
                </Space>
            ),
        },
    ];

    const changePage = function (page) {
        if (page === 0) {
            loadItems(true);
        } else {
            filterDispatch({
                type: ACTIONS.CHANGE_PAGE,
                payload: {
                    page: page,
                },
            });
        }
    };

    function parseFilterValue(value) {
        if (!value) {
            return null;
        }

        if (Array.isArray(value)) {
            return value[0];
        }

        return value;
    }

    const changeFilter = function (value, item) {
        const statusFilter = parseFilterValue(item.status);
        const customerChannelFilter = parseFilterValue(item.customerChannel);
        const customerStatusFilter = parseFilterValue(item.customerStatus);
        const ownedByAgentTypeFilter = parseFilterValue(item.ownedByAgentType);
        if (statusFilter !== filterState.status) {
            filterDispatch({
                type: ACTIONS.CHANGE_STATUS,
                payload: {
                    status: statusFilter,
                },
            });
        }
        if (customerChannelFilter !== filterState.customerChannel) {
            filterDispatch({
                type: ACTIONS.CHANGE_CHANNEL,
                payload: {
                    customerChannel: customerChannelFilter,
                },
            });
        }
        if (customerStatusFilter !== filterState.customerStatus) {
            filterDispatch({
                type: ACTIONS.CHANGE_CUSTOMER_STATUS,
                payload: {
                    customerStatus: customerStatusFilter,
                },
            });
        }
        if (ownedByAgentTypeFilter !== filterState.ownedByAgentType) {
            filterDispatch({
                type: ACTIONS.CHANGE_OWNER_TYPE,
                payload: {
                    ownedByAgentType: ownedByAgentTypeFilter,
                },
            });
        }
    };

    const loadItemsByIds = function (chatsIds) {
        api.monitoring()
            .getByIds(filterState.paginator.page, chatsIds)
            .then((response) => {
                setChats(response.data.chats);
                filterDispatch({
                    type: ACTIONS.SET_PAGINATOR,
                    payload: {
                        paginator: response.data.paginator,
                    },
                });
            });
    };

    const loadItems = function (firstPage) {
        const filterStartDate = momentToYYMMDD(filterState.startDate) + " 00:00:00";
        const filterEndDate = momentToYYMMDD(filterState.endDate) + " 23:59:59";
        const page = firstPage ? 0 : filterState.paginator.page;
        setIsLoading(true);
        const listParams = {
            page: page,
            status: filterState.status,
            startDateTime: filterStartDate,
            endDateTime: filterEndDate,
            dateFieldFilter: "CREATED_ON",
            customerChannel: filterState.customerChannel,
            customerId: filterState.customerId,
            participatingAgentId: filterState.participatingAgentId,
            customerStatus: filterState.customerStatus,
            searchText: filterState.searchText,
            tagsId: filterState.tagsId,
            groupedTagsIds: filterState.groupedTagsIds,
            sortBy: "CREATED_ON",
            useSortConfig: false,
            owner: filterState.ownedByAgentType,
        };
        api.monitoring()
            .list(...Object.values(listParams))
            .then((response) => {
                filterDispatch({
                    type: ACTIONS.SET_PAGINATOR,
                    payload: {
                        paginator: response.data.paginator,
                    },
                });
                setChats(response.data.conversations);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(true);
            })
            .then(() => setIsLoading(false));
    };

    function disabledDate(current) {
        return current && current > moment().endOf("day");
    }

    function onDatesChange(dates) {
        if (dates && dates[0] && dates[1]) {
            const diff = dates[1].diff(dates[0], "days");
            const isError = diff > 32;
            setDatesError(diff > 32);
            if (isError) {
                return;
            }
        }

        filterDispatch({
            type: ACTIONS.CHANGE_DATE,
            payload: {
                startDate: dates[0],
                endDate: dates[1],
            },
        });
        setDates(dates);
    }

    const momentToYYMMDD = (momentObj) => {
        let baseDate = momentObj;
        if (typeof momentObj === "string") {
            baseDate = moment(momentObj);
        }
        let dateObj = baseDate.toDate();
        let year = dateObj.getUTCFullYear();
        let month = dateObj.getUTCMonth() + 1;
        let day = dateObj.getUTCDate();
        return year + "-" + month + "-" + day;
    };

    const parseChannel = function (channel) {
        switch (channel) {
            case "OPTIWE_CHAT":
                return "Web chat";
            case "UI_INTEGRATION":
                return "Integrated chat";
            case "FACEBOOK":
                return "Facebook Messenger";
            case "WHATSAPP":
                return "Whatsapp";
            case "INSTAGRAM":
                return "Instagram";
            case "SMS":
                return "SMS";
            case "FACEBOOK_COMMENT":
                return "Facebook comment";
            default:
                return "Chat";
        }
    };

    function changePresetDate(period) {
        setDates([moment().subtract(1, period), moment()]);
        filterDispatch({
            type: ACTIONS.CHANGE_DATE,
            payload: {
                startDate: moment().subtract(1, period),
                endDate: moment(),
            },
        });
    }

    function handleCustomerSearch(text) {
        clearTimeout(customerSearchTypingTimeoutRef.current);
        customerSearchTypingTimeoutRef.current = setTimeout(() => {
            setSelectLoader(true);
            setCustomersList([]);
            api.customers()
                .list(0, text)
                .then((res) => {
                    setSelectLoader(false);
                    setCustomersList(res.data.customers);
                });
        }, [500]);
    }

    function handleCustomerSelect(id) {
        filterDispatch({
            type: ACTIONS.CHANGE_CUSTOMER,
            payload: {
                customerId: id ? id : null,
            },
        });
    }

    function handleAgentSelect(id) {
        filterDispatch({
            type: ACTIONS.CHANGE_AGENT,
            payload: {
                participatingAgentId: id ? id : null,
            },
        });
    }

    function handleSelectedTags(selectedTags) {
        filterDispatch({
            type: ACTIONS.CHANGE_TAGS,
            payload: {
                tagsId: selectedTags.map((tag) => tag.key),
            },
        });
    }

    function handleSelectedGroups() {
        const groupedTagsIds = [];
        for (const group of selectedGroups) {
            const includedTags = group.conversationLabels.filter((tag) =>
                selectedTags.map((t) => t.id).includes(tag.id)
            );
            const includedTagsIds = includedTags.map((t) => t.id);
            groupedTagsIds.push(includedTagsIds);
        }
        const selectedGroupsIds = selectedGroups.map((g) => g.id);

        filterDispatch({
            type: ACTIONS.CHANGE_TAGS_GROUPS,
            payload: {
                groupedTagsIds: groupedTagsIds,
                selectedGroupsIds: selectedGroupsIds,
            },
        });
    }

    async function loadAndGetTags() {
        try {
            const res = await api.tags().list(0);
            const tags = res.data.conversationLabels;
            setTags(tags);
            return tags;
        } catch (err) {
            console.log(err);
        }
    }

    async function loadAndGetGroups() {
        try {
            const res = await api.tagsGroups().list();
            const groups = res.data.conversationLabelGroups;
            setGroups(groups);
            return groups;
        } catch (err) {
            console.log(err);
        }
    }

    async function addGroupToSelected() {
        if (!selectedGroup) {
            return;
        }
        const res = await api.tagsGroups().get(selectedGroup.id);
        const group = res.data;
        const newGroups = [...selectedGroups, group];
        setSelectedGroups(newGroups);
        setSelectedGroup(null);
    }

    function deselectGroup(group_id) {
        const group = selectedGroups.find((g) => g.id === group_id);
        const newGroups = selectedGroups.filter((g) => g.id !== group_id);
        const newTags = selectedTags.filter(
            (t) => !group.conversationLabels.map((g) => g.id).includes(t.id)
        );

        setSelectedGroups(newGroups);
        setSelectedTags(newTags);
    }

    function closeFilterByGroupsModal() {
        handleSelectedGroups();
        setFilterByGroupsModalIsVisible(false);
    }

    async function buildGroupedSearchState(tags, groupedTagsIds, groups, selectedGroupsIds) {
        const selectedTagsIds = [].concat(...groupedTagsIds);
        const selectedTags = tags.filter((tag) => selectedTagsIds.includes(tag.id));
        const selectedGroups = [];
        for (const id of selectedGroupsIds) {
            const res = await api.tagsGroups().get(id);
            selectedGroups.push(res.data);
        }
        setSelectedTags(selectedTags);
        setSelectedGroups(selectedGroups);
    }

    function handleSearchChange(e) {
        const searchText = e.target.value;
        setSearchInputVal(searchText);
        clearTimeout(searchTypingTimeoutRef.current);
        searchTypingTimeoutRef.current = setTimeout(() => {
            filterDispatch({
                type: ACTIONS.CHANGE_SEARCH_TEXT,
                payload: {
                    searchText: searchText ? searchText : null,
                },
            });
        }, [500]);
    }

    useEffect(() => {
        loadItems(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        filterState.status,
        filterState.startDate,
        filterState.endDate,
        filterState.customerChannel,
        filterState.customerId,
        filterState.participatingAgentId,
        filterState.customerStatus,
        filterState.ownedByAgentType,
        filterState.searchText,
        filterState.tagsId,
        filterState.groupedTagsIds,
    ]);

    useEffect(() => {
        // todo: work this with filters
        if (location.state && location.state.userId) {
            loadItemsByIds([location.state.userId]);
        } else {
            if (filterState.paginator.page > 0) {
                loadItems();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState.paginator.page]);

    useEffect(() => {
        async function loadStateAsync() {
            setInitialLoadIsLoading(true);
            const tags = await loadAndGetTags();
            const groups = await loadAndGetGroups();
            if (location.state) {
                if (location.state.filters) {
                    let startDate = filterState.startDate;
                    let endDate = filterState.endDate;
                    if (typeof startDate === "string" || typeof endDate === "string") {
                        startDate = moment(startDate);
                        endDate = moment(endDate);
                    }
                    setSearchInputVal(filterState.searchText);
                    if (filterState.groupedTagsIds) {
                        await buildGroupedSearchState(
                            tags,
                            filterState.groupedTagsIds,
                            groups,
                            filterState.selectedGroupsIds
                        );
                    }
                }
            }
            setInitialLoadIsLoading(false);
        }
        if (appContext.state?.workspaceConfigs?.language === "ENGLISH") {
            setDateFormat("YYYY/MM/DD");
        }
        if (appContext.state?.workspaceConfigs?.language === "ENGLISH") {
            setDateFormat("YYYY/MM/DD");
        }
        loadStateAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setIsLoading(true);
        api.users()
            .list(usersPaginatorPage, 32)
            .then((res) => {
                setAgents([...agents, ...res.data.users]);
                setUsersPaginatorHasNext(res.data.paginator.hasNext);
                setIsLoading(false);
            });
    }, [usersPaginatorPage]);

    function selecetSetSelectedGroup(value) {
        const group = groups.find((g) => g.id === value.key);
        setSelectedGroup(group);
    }

    function handleDeselectTag(value) {
        const newSelectedTags = selectedTags.filter((t) => t.id !== value.key);
        setSelectedTags(newSelectedTags);
    }
    return (
        <div className="opt-view opt-view--monitoring-list">
            <div className="opt-view-header">
                <h1 className="opt-title">
                    {t("monitoringList.title")}{" "}
                    <TitleTooltip content="Monitoring each conversation to understand the recommendations and decisions made by the AI" />
                </h1>
                <div className="opt-view-header__actions">
                    <ul className="filters">
                        <li onClick={() => changePresetDate("month")} className={`filter`}>
                            {t("monitoringList.lastMonth")}
                        </li>
                        <li onClick={() => changePresetDate("week")} className={`filter`}>
                            {t("monitoringList.lastWeek")}
                        </li>
                    </ul>
                    <div className="date-picker">
                        <Tooltip
                            title={datesError ? t("monitoringList.datesError") : ""}
                            visible={datesError}
                        >
                            <RangePicker
                                allowClear={false}
                                format={dateFormat}
                                value={dates}
                                onChange={onDatesChange}
                                disabledDate={disabledDate}
                            />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div className="opt-view-header__filters">
                {initialLoadIsLoading ? (
                    <Spin
                        style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                        }}
                    />
                ) : (
                    <>
                        <div className="opt-input-groups opt-table-select-filter">
                            <span className="opt-label">
                                {t("monitoringList.filterByCustomerLabel")}
                            </span>{" "}
                            <br />
                            <Select
                                style={{ width: "100%" }}
                                showSearch
                                allowClear
                                filterOption={false}
                                placeholder={t("monitoringList.searchByCustomerPlaceholder")}
                                notFoundContent={selectLoader ? <Spin size="small" /> : null}
                                onChange={handleCustomerSelect}
                                onSearch={handleCustomerSearch}
                            >
                                {customersList.map((d) => (
                                    <Select.Option key={d.id} value={d.id}>
                                        {d.fullName} | {d.email}
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                        <div
                            className="opt-input-group opt-table-select-filter"
                            style={{ marginLeft: "20px" }}
                        >
                            <span className="opt-label">
                                {t("monitoringList.filterByAgentLabel")}
                            </span>{" "}
                            <br />
                            <Select
                                value={filterState.participatingAgentId}
                                style={{ width: "100%" }}
                                placeholder={t("monitoringList.selectAgentPlaceholder")}
                                onChange={handleAgentSelect}
                                allowClear
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        {usersPaginatorHasNext && (
                                            <LoadMore
                                                onClick={() =>
                                                    setUsersPaginatorPage(
                                                        (usersPaginatorPage) =>
                                                            usersPaginatorPage + 1
                                                    )
                                                }
                                                style={{ marginTop: "0px", marginBottom: "15px" }}
                                            />
                                        )}
                                    </>
                                )}
                            >
                                {Array.isArray(agents) &&
                                    agents.map((agent) => (
                                        <Select.Option key={agent.id} value={agent.id}>
                                            {agent.email}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </div>
                        <div
                            className="opt-input-group opt-table-select-filter"
                            style={{ marginLeft: "20px" }}
                        >
                            <span className="opt-label">
                                {t("monitoringList.filterByConversationTagLabel")}
                            </span>{" "}
                            <br />
                            {groups && groups.length ? (
                                <>
                                    <Button
                                        style={{ width: "100%" }}
                                        onClick={() => setFilterByGroupsModalIsVisible(true)}
                                    >
                                        {t("monitoringList.selectTagsToFilter")}
                                    </Button>
                                    <Modal
                                        title="Filter by conversation tag groups"
                                        visible={filterByGroupsModalIsVisible}
                                        onCancel={closeFilterByGroupsModal}
                                        footer={
                                            <Button onClick={closeFilterByGroupsModal}>
                                                {t("close")}
                                            </Button>
                                        }
                                    >
                                        <h3>Select a group</h3>
                                        <div style={{ display: "flex" }}>
                                            <Select
                                                labelInValue
                                                style={{ width: "100%" }}
                                                placeholder="Select a group"
                                                value={
                                                    selectedGroup
                                                        ? { value: selectedGroup.name }
                                                        : []
                                                }
                                                onSelect={(value) => selecetSetSelectedGroup(value)}
                                            >
                                                {groups
                                                    .filter(
                                                        (group) =>
                                                            !selectedGroups
                                                                .map((g) => g.id)
                                                                .includes(group.id)
                                                    )
                                                    .map((g) => (
                                                        <Option key={g.id}>{g.name}</Option>
                                                    ))}
                                            </Select>
                                            <Button
                                                onClick={addGroupToSelected}
                                                style={{ marginLeft: "8px" }}
                                            >
                                                Add group
                                            </Button>
                                        </div>
                                        {selectedGroups.map((g) => (
                                            <div
                                                key={`groupdiv:${g.id}`}
                                                style={{
                                                    maxWidth: "100%",
                                                    overflow: "auto",
                                                    marginTop: "8px",
                                                }}
                                            >
                                                <div style={{ display: "flex" }}>
                                                    <h3>{g.name}</h3>
                                                    <Button
                                                        danger
                                                        type="text"
                                                        onClick={() => {
                                                            deselectGroup(g.id);
                                                        }}
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: 0,
                                                        }}
                                                    >
                                                        Remove group
                                                    </Button>
                                                </div>
                                                <Select
                                                    labelInValue
                                                    mode="multiple"
                                                    style={{ width: "100%" }}
                                                    defaultValue={
                                                        selectedTags
                                                            ? selectedTags.filter((tag) =>
                                                                  g.conversationLabels
                                                                      .map((t) => t.id)
                                                                      .includes(tag.id)
                                                              )
                                                            : []
                                                    }
                                                    onSelect={(value) =>
                                                        setSelectedTags([
                                                            ...selectedTags,
                                                            tags.find((t) => t.id === value.key),
                                                        ])
                                                    }
                                                    onDeselect={(value) => handleDeselectTag(value)}
                                                    placeholder="Select tags for filter..."
                                                >
                                                    {g.conversationLabels.map((tag) => (
                                                        <Option key={tag.id} value={tag.id}>
                                                            {tag.value}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </div>
                                        ))}
                                    </Modal>
                                </>
                            ) : (
                                <Select
                                    labelInValue
                                    mode="multiple"
                                    style={{ width: "100%" }}
                                    allowClear
                                    filterOption={false}
                                    placeholder={t(
                                        "monitoringList.selectConversationTagPlaceholder"
                                    )}
                                    defaultValue={tags.filter((tag) =>
                                        (filterState.tagsId || []).includes(tag.id)
                                    )}
                                    onChange={handleSelectedTags}
                                >
                                    {tags.map((tag) => (
                                        <Option key={tag.id} value={tag.id}>
                                            {tag.value}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </div>
                        <div
                            className="opt-input-group opt-table-select-filter"
                            style={{ marginLeft: "20px" }}
                        >
                            <span className="opt-label">{t("monitoringList.search")}</span> <br />
                            <Search
                                allowClear
                                value={searchInputVal}
                                onChange={handleSearchChange}
                                placeholder={t("monitoringList.search")}
                            />
                        </div>
                    </>
                )}
            </div>
            <Loader isLoading={isLoading} />
            <div className="opt-card opt-card--table opt-card--no-padding">
                <MonitoringTable
                    columns={columns}
                    chats={chats}
                    paginator={filterState.paginator}
                    onChangeCb={changeFilter}
                    onChangePageCb={changePage}
                />
            </div>
        </div>
    );
}
export default MonitoringList;
