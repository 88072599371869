import React, { useState } from "react";
import { Table, Space, Button, Tag, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Route, useHistory, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WithPaginator from "../../../components/WithPaginator/WithPaginator";
import TitleTooltip from "../../../components/TitleTooltip/TitleTooltip";
import CampaignForm from "./CampaignForm";
import SendCampaign from "./SendCampaign";

function Templates({
    templateList,
    campaignList,
    campaignPaginator,
    reLoadCb,
    loadMoreCb,
    configs,
}) {
    const history = useHistory();
    const [isModalVisible, setIsModalVisible] = useState();
    const [selectedCampaign, setSelectedCampaign] = useState({});
    const { t } = useTranslation();

    const getEffectiveCustomersIds = (row) =>
        row.effectiveCustomers ? row.effectiveCustomers.map((item) => item.id) : [];
    const getInboundCustomersIds = (row) =>
        row.inboundCustomers ? row.inboundCustomers.map((item) => item.id) : [];
    const getOpenedCustomersIds = (row) =>
        row.messageOpenedCustomers ? row.messageOpenedCustomers.map((item) => item.id) : [];
    const getFailedCustomersIds = (row) =>
        row.failedCustomers ? row.failedCustomers.map((item) => item.id) : [];
    const getUnsubscribedCustomersId = (row) =>
        row.unsubscribedCustomers ? row.unsubscribedCustomers.map((item) => item.id) : [];

    const columns = [
        {
            title: t("outbound.campaignObj.name"),
            dataIndex: "name",
            key: "name",
        },
        {
            title: t("outbound.campaignObj.template"),
            dataIndex: "template",
            key: "temaplate",
            render: (record) => {
                const selected =
                    templateList && templateList.filter((template) => template.id === record.id)[0];
                return selected ? selected.name : "-";
            },
        },
        {
            title: (
                <>
                    <span style={{ marginRight: "5px" }}>
                        {t("outbound.campaignObj.effectiveCustomers")}
                    </span>
                    <TitleTooltip
                        content={t("outbound.campaignObj.effectiveCustomersFieldMessage")}
                    />
                </>
            ),
            dataIndex: "effectiveCustomers",
            key: "effectiveCustomers",
            render: (record, row) => (
                <NavLink
                    to={{
                        pathname: `/dashboard/customers/`,
                        state: { customersIds: getEffectiveCustomersIds(row) },
                    }}
                    className="opt-table__button"
                >
                    {Object.keys(row.effectiveCustomers).length}
                    <Space>
                        <div style={{ marginLeft: "8px" }}>
                            <Button
                                shape="circle"
                                size="small"
                                icon={
                                    <SearchOutlined style={{ position: "relative", top: "2px" }} />
                                }
                            ></Button>
                        </div>
                    </Space>
                </NavLink>
            ),
        },
        {
            title: (
                <>
                    <span style={{ marginRight: "5px" }}>
                        {t("outbound.campaignObj.messageOpenedCustomers")}
                    </span>
                    <TitleTooltip
                        content={t("outbound.campaignObj.messageOpenedCustomersFieldMessage")}
                    />
                </>
            ),
            dataIndex: "messageOpenedCustomers",
            key: "messageOpenedCustomers",
            render: (record, row) =>
                configs ? (
                    <NavLink
                        to={{
                            pathname: `/dashboard/customers/`,
                            state: { customersIds: getOpenedCustomersIds(row) },
                        }}
                        className="opt-table__button"
                    >
                        {Object.keys(row.messageOpenedCustomers).length}
                        <Space>
                            <div style={{ marginLeft: "8px" }}>
                                <Button
                                    shape="circle"
                                    size="small"
                                    icon={
                                        <SearchOutlined
                                            style={{ position: "relative", top: "2px" }}
                                        />
                                    }
                                ></Button>
                            </div>
                        </Space>
                    </NavLink>
                ) : (
                    "-"
                ),
        },
        {
            title: (
                <>
                    <span style={{ marginRight: "5px" }}>
                        {t("outbound.campaignObj.conversionRate")}
                    </span>
                    <TitleTooltip content={t("outbound.campaignObj.conversionRateTooltip")} />
                </>
            ),
            dataIndex: "inboundCustomers",
            key: "inboundCustomers",
            render: (record, row) =>
                configs ? (
                    <NavLink
                        to={{
                            pathname: `/dashboard/customers/`,
                            state: { customersIds: getInboundCustomersIds(row) },
                        }}
                        className="opt-table__button"
                    >
                        {Object.keys(row.inboundCustomers).length}
                        <Space>
                            <div style={{ marginLeft: "8px" }}>
                                <Button
                                    shape="circle"
                                    size="small"
                                    icon={
                                        <SearchOutlined
                                            style={{ position: "relative", top: "2px" }}
                                        />
                                    }
                                ></Button>
                            </div>
                        </Space>
                    </NavLink>
                ) : (
                    "-"
                ),
        },
        {
            title: (
                <>
                    <span style={{ marginRight: "5px" }}>
                        {t("outbound.campaignObj.failedReceptions")}
                    </span>
                    <TitleTooltip content={t("outbound.campaignObj.failedReceptionsTooltip")} />
                </>
            ),
            dataIndex: "failedCustomers",
            key: "failedCustomers",
            render: (record, row) =>
                configs ? (
                    <NavLink
                        to={{
                            pathname: `/dashboard/customers/`,
                            state: { customersIds: getFailedCustomersIds(row) },
                        }}
                        className="opt-table__button"
                    >
                        {Object.keys(row.failedCustomers).length}
                        <Space>
                            <div style={{ marginLeft: "8px" }}>
                                <Button
                                    shape="circle"
                                    size="small"
                                    icon={
                                        <SearchOutlined
                                            style={{ position: "relative", top: "2px" }}
                                        />
                                    }
                                ></Button>
                            </div>
                        </Space>
                    </NavLink>
                ) : (
                    "-"
                ),
        },
        {
            title: "Clientes desuscriptos",
            dataIndex: "unsubscribedCustomers",
            key: "unsubscribedCustomers",
            render: (record, row) =>
                configs ? (
                    <NavLink
                        to={{
                            pathname: `/dashboard/customers/`,
                            state: { customersIds: getUnsubscribedCustomersId(row) },
                        }}
                        className="opt-table__button"
                    >
                        {Object.keys(row.unsubscribedCustomers).length}
                        <Space>
                            <div style={{ marginLeft: "8px" }}>
                                <Button
                                    shape="circle"
                                    size="small"
                                    icon={
                                        <SearchOutlined
                                            style={{ position: "relative", top: "2px" }}
                                        />
                                    }
                                ></Button>
                            </div>
                        </Space>
                    </NavLink>
                ) : (
                    "-"
                ),
        },
        {
            title: t("outbound.campaignObj.action"),
            key: "action",
            render: (record) => (
                <Space size="middle">
                    {record.status === "CREATED" ? (
                        <>
                            <Button onClick={() => editCampaign(record)}>
                                {t("outbound.campaignObj.details")}
                            </Button>
                            <Button
                                style={{ marginLeft: "5px" }}
                                type="primary"
                                onClick={() => openCampaignModal(record)}
                            >
                                {t("outbound.campaignObj.send")}
                            </Button>
                            <Tag
                                style={{ marginLeft: "5px" }}
                                color={parseStatusTag(record.status)}
                            >
                                {parseStatusTranslation(record.status)}
                            </Tag>
                        </>
                    ) : (
                        <>
                            <Button onClick={() => editCampaign(record)}>
                                {t("outbound.campaignObj.details")}
                            </Button>
                            {record &&
                            record.status === "FAILED" &&
                            record.failedCustomers &&
                            record.failedCustomers.length > 0 ? (
                                <Button
                                    style={{ marginLeft: "5px" }}
                                    type="primary"
                                    onClick={() => openCampaignModal(record)}
                                >
                                    {t("outbound.campaignObj.resend")}
                                </Button>
                            ) : (
                                <></>
                            )}
                            <Tag
                                style={{ marginLeft: "5px" }}
                                color={parseStatusTag(record.status)}
                            >
                                {parseStatusTranslation(record.status)}
                            </Tag>
                        </>
                    )}
                </Space>
            ),
        },
    ];
    const parseStatusTag = (status) => {
        if (status === "SENT") {
            return "green";
        } else if (status === "SENDING") {
            return "orange";
        } else if (status === "FAILED") {
            return "red";
        } else if (status === "AWAITING") {
            return "yellow";
        } else {
            return "blue";
        }
    };
    const parseStatusTranslation = (status) => {
        if (status === "SENT") {
            return t("outbound.campaignObj.sent");
        } else if (status === "SENDING") {
            return t("outbound.campaignObj.sending");
        } else if (status === "FAILED") {
            return t("outbound.campaignObj.failed");
        } else if (status === "AWAITING") {
            return t("outbound.campaignObj.awaiting");
        } else {
            return t("outbound.campaignObj.created");
        }
    };
    const newCampaign = () => {
        setSelectedCampaign(null);
        history.push("/dashboard/outbound/campaigns/form");
    };
    const editCampaign = (record) => {
        setSelectedCampaign(record);
        history.push("/dashboard/outbound/campaigns/form");
    };
    const openCampaignModal = function (record) {
        setSelectedCampaign(record);
        setIsModalVisible(true);
    };

    return (
        <>
            <Route exact path="/dashboard/outbound/campaigns">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: "20px",
                    }}
                >
                    <Button type="primary" onClick={newCampaign}>
                        {t("outbound.campaignObj.createNewCampaign")}
                    </Button>
                </div>
                <div className="opt-card" style={{ padding: 0 }}>
                    <div className="opt-card opt-card--table opt-card--no-padding">
                        <WithPaginator
                            page={campaignPaginator.page}
                            hasNext={campaignPaginator.hasNext}
                            changePageCb={loadMoreCb}
                            showPage={true}
                            viewMoreCb={null}
                        >
                            <Table dataSource={campaignList} columns={columns} pagination={false} />
                        </WithPaginator>
                    </div>
                </div>
            </Route>
            <Route exact path="/dashboard/outbound/campaigns/form">
                <CampaignForm
                    templateList={templateList}
                    selectedCampaign={selectedCampaign}
                    reloadCb={reLoadCb}
                />
            </Route>
            <SendCampaign
                configs={configs}
                isModalVisible={isModalVisible}
                setIsModalVisibleCb={setIsModalVisible}
                selectedCampaign={selectedCampaign}
                templateList={templateList}
                closeCb={reLoadCb}
            />
        </>
    );
}
export default Templates;
