import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Divider, Button, Input, Tooltip, message, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TitleTooltip from "../../../components/TitleTooltip/TitleTooltip";
import { faSalesforce } from "@fortawesome/free-brands-svg-icons";
import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import {useSalesforceIntegration} from "./useSalesforceIntegration";

function IntegrationsSettings() {
    const { t } = useTranslation();
    const [salesforceIntegration, setSalesforceIntegration, connectSalesforceIntegration, isLoading] = useSalesforceIntegration();

    function onChangeInput(e: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target;
        setSalesforceIntegration((prevState: any) => ({
            ...prevState,
            [name]: value
          }));
    }

    function onConnectSalesforceIntegration() {
        const { clientId, clientSecret, username, password } = salesforceIntegration
        if (!clientId || !clientSecret || !username || !password) {
            message.error(t("settings.integrations.fieldsRequired"))
            return
        }
        connectSalesforceIntegration(clientId, clientSecret, username, password);
    }

    return (
        <>
            <div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                    <FontAwesomeIcon icon={faSalesforce} size="3x" />
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "20px",
                    }}
                >
                    <span style={{ fontWeight: "bold", marginRight: "10px" }}>
                        {t("settings.integrations.salesforceTitle")}
                    </span>
                    {salesforceIntegration.isConnected && !isLoading &&
                        <Tooltip title={t("settings.integrations.connected")} placement="right">
                            <FontAwesomeIcon
                                icon={faCheck}
                                style={{ color: "green", fontSize: "16px" }}
                            />
                        </Tooltip>
                    }
                    {!salesforceIntegration.isConnected && !isLoading &&
                        <Tooltip title={t("settings.integrations.notConnected")} placement="right">
                        <FontAwesomeIcon
                            icon={faExclamation}
                            style={{ color: "orange", fontSize: "16px" }}
                        />
                    </Tooltip>
                    }
                </div>
            </div>
                <Row>

                    <Col span={1}>
                        <Divider type="vertical" style={{ height: "100%" }} />
                    </Col>
                    {(
                        
                        <div
                            style={{
                                marginLeft: "15px",
                                marginRight: "0px",
                                marginTop: "5px",
                                marginBottom: "2px",
                            }}
                        >
                            {isLoading ? <Spin/> : <>
                                <div style={{ display: "flex", marginBottom: "15px"}} key={"clientId"}>
                                    <span style={{ marginRight: "10px"}}>
                                        {t("settings.integrations.salesforceClientID")}
                                    </span>
                                    <Input 
                                        style={{ marginRight: "10px" }} 
                                        value={salesforceIntegration?.clientId} 
                                        onChange={onChangeInput} 
                                        name={"clientId"} 
                                    />
                                    <TitleTooltip
                                        content={t(
                                            "settings.integrations.salesforceClientIDTooltip"
                                        )}
                                    />
                                </div>
                                <div style={{ display: "flex", marginBottom: "15px" }} key={"clientSecret"} >
                                    <span style={{ marginRight: "10px" }}>
                                        {t("settings.integrations.salesforceClientSecret")}
                                    </span>
                                    <Input.Password 
                                        style={{ marginRight: "10px" }} 
                                        value={salesforceIntegration?.clientSecret} 
                                        onChange={onChangeInput} 
                                        name={"clientSecret"} 
                                        iconRender={() => false}
                                        autoComplete="off"
                                    />
                                    <TitleTooltip
                                        content={t(
                                            "settings.integrations.salesforceClientSecretTooltip"
                                        )}
                                    />
                                </div>
                                <div style={{ display: "flex", marginBottom: "15px" }} key={"username"} >
                                    <span style={{ marginRight: "10px" }}>
                                        {t("settings.integrations.salesforceUsername")}
                                    </span>
                                    <Input 
                                        style={{ marginRight: "10px" }} 
                                        value={salesforceIntegration?.username} 
                                        onChange={onChangeInput} 
                                        name={"username"} 

                                    />
                                    <TitleTooltip
                                        content={t(
                                            "settings.integrations.salesforceUsernameTooltip"
                                        )}
                                    />
                                </div>
                                <div style={{ display: "flex", marginBottom: "15px" }} key={"password"} >
                                    <span style={{ marginRight: "10px" }}>
                                        {t("settings.integrations.salesforcePassword")}
                                    </span>
                                    <Input.Password 
                                        style={{ marginRight: "10px" }} 
                                        value={salesforceIntegration?.password} 
                                        onChange={onChangeInput} 
                                        name={"password"} 
                                        iconRender={() => false}
                                        autoComplete="off"
                                    />
                                    <TitleTooltip
                                        content={t(
                                            "settings.integrations.salesforcePasswordTooltip"
                                        )}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        marginLeft: "5px", 
                                        borderRadius: "15px"
                                    }}
                                >
                                    <Button
                                        className="opt-icon-button"
                                        style={{ borderRadius: "10px" }}
                                        onClick={onConnectSalesforceIntegration}
                                    >
                                        {t("settings.integrations.connect")}
                                    </Button>
                                </div>
                            </>}
                        </div>
                    )}
                </Row>
                
        </>
    );
}

export default IntegrationsSettings;
