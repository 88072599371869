import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import {
    Button,
    Card,
    DatePicker,
    Input,
    Modal,
    Radio,
    Select,
    Space,
    Spin,
    Table,
    Tag,
    message,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { AppContext } from "../../../components/AppContext";
import LoadMore from "../../../components/LoadMore/LoadMore";
import Loader from "../../../components/Loader/Loader";
import api from "../../../requests/api";
import { TranslatedTabList, UISTATES } from "./configs/Constants";
import AgentReports from "./tabs/AgentReports";
import AnswersReports from "./tabs/answers/AnswersReports";
import ChatReports from "./tabs/general/ChatReports";
import LabelsReports from "./tabs/labels/LabelsReports";
import TagsReports from "./tabs/tags/TagsReports";

const { RangePicker } = DatePicker;
function Reports() {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();
    const [rangeType, setRangeType] = useState("day");
    const [dateField, setDateField] = useState("CREATED_ON");
    const [activeTab, setActiveTab] = useState("general");
    const [uiState, setUiState] = useState(UISTATES.FINISHED);
    const [selectedDates, setSelectedDates] = useState([moment().subtract(1, "weeks"), moment()]);
    const [chatsDataPoints, setChatsDataPoints] = useState([]);
    const [agentDataPoints, setAgentDataPoints] = useState([]);
    const [agentTimeInStateData, setAgentTimeInStateData] = useState([]);
    const [agentAvgTimeInStateData, setAgentAvgTimeInStateData] = useState([]);
    const [tagsDataPoints, setTagsDataPoints] = useState([]);
    const [answerDataPoints, setAnswerDataPoints] = useState([]);
    const [labelsDataPoints, setLabelsDataPoints] = useState([]);
    const [timeInteractionsDataPoints, setTimeInteractionsDataPoints] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [users, setUsers] = useState([]);
    const [usersPaginatorPage, setUsersPaginatorPage] = useState(0);
    const [usersPaginatorHasNext, setUsersPaginatorHasNext] = useState(false);
    const [usersIdToAgentIdMap, setUsersIdToAgentIdMap] = useState({});
    const [selectedTag, setSelectedTag] = useState(null);
    const [tags, setTags] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [labels, setLabels] = useState([]);
    const [layoutType, setLayoutType] = useState("list");
    const [chartsRange, setChartsRange] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [answerPage, setAnswerPage] = useState(0);
    const [answerPaginatorHasNext, setAnswerPaginatorHasNext] = useState(false);
    const [selectedAnswersToCharts, setSelectedAnswersToCharts] = useState([]);
    const [selectedLabelsToCharts, setSelectedLabelsToCharts] = useState([]);
    const [modalSelectedAnswers, setModalSelectedAnswers] = useState([]);
    const [modalSelectedLabels, setModalSelectedLabels] = useState([]);
    const [searchKeyWords, setSearchKeyWords] = useState([]);
    const [searchLabelsInput, setSearchLabelsInput] = useState([]);
    const [answerModalVisible, setAnswerModalVisible] = useState(false);
    const [labelsModalVisible, setLabelsModalVisible] = useState(false);
    const [labelsToTable, setLabelsToTable] = useState([]);
    const handleRangeChange = (value) => {
        setRangeType(value);
        setSelectedDates([moment().subtract(1, "weeks"), moment()]);
    };
    const handleDateFieldChange = (value) => {
        setDateField(value);
    };
    const renderTab = (key) => {
        if (key === "general") {
            return (
                <ChatReports
                    daysData={chatsDataPoints}
                    hoursData={timeInteractionsDataPoints}
                    uistate={uiState}
                    chartsRange={chartsRange}
                    layout={layoutType}
                />
            );
        }
        if (key === "agents") {
            return (
                <AgentReports
                    data={agentDataPoints}
                    uistate={uiState}
                    chartsRange={chartsRange}
                    layout={layoutType}
                    timeInState={agentTimeInStateData}
                    avgTimeInState={agentAvgTimeInStateData}
                />
            );
        }
        if (key === "tags") {
            return (
                <TagsReports
                    data={tagsDataPoints}
                    uistate={uiState}
                    chartsRange={chartsRange}
                    layout={layoutType}
                />
            );
        }
        if (key === "answers") {
            return (
                <AnswersReports
                    data={answerDataPoints}
                    selected={selectedAnswersToCharts}
                    chartsRange={chartsRange}
                />
            );
        }
        if (key === "labels") {
            return (
                <LabelsReports
                    data={labelsDataPoints}
                    selected={selectedLabelsToCharts}
                    chartsRange={chartsRange}
                />
            );
        }
    };
    const handleDateChange = (dates) => {
        setSelectedDates(dates);
    };
    const handleLayout = (e) => {
        setLayoutType(e.target.value);
    };
    const handleTab = (tab) => {
        setActiveTab(tab);
    };
    const handleUserChange = (userId) => {
        setSelectedUser(userId);
        if (selectedDates) {
            loadAgentChartData(userId);
        }
    };
    const handleTagChange = (id) => {
        setSelectedTag(id);
        if (selectedDates) {
            loadTagChartData(id);
        }
    };

    const loadAgentChartData = (userId) => {
        if (!userId) {
            setUiState(UISTATES.FINISHED);
            return;
        }
        setUiState(UISTATES.LOADING);

        const startDate = selectedDates[0].format("YYYY-MM-DD");
        const endDate = selectedDates[1].format("YYYY-MM-DD");
        const frequency = rangeType.toUpperCase() + "S";
        const agentId = usersIdToAgentIdMap[userId];

        Promise.all([
            api.reports().agents(startDate, endDate, frequency, userId, dateField),
            api.reports().agentsAvgTimeInState(startDate, endDate, agentId),
            api.reports().agentsTimeInState(startDate, endDate, frequency, agentId),
        ]).then(([resAgentStats, resAvgTime, resTime]) => {
            if (resAgentStats.data.agentsStats.length > 0) {
                setAgentDataPoints(resAgentStats.data.agentsStats[0].dataPoints);
            } else {
                setAgentDataPoints([]);
            }
            if (resAvgTime.data.agentsStateStatistics.length > 0) {
                const agentStats = resAvgTime.data.agentsStateStatistics.filter(
                    (s) => s.agentId === agentId
                )[0].stats;
                setAgentAvgTimeInStateData(agentStats);
            }
            if (resTime.data.agentsStateStatistics.length > 0) {
                const stats = resTime.data.agentsStateStatistics.find(
                    (s) => s.agentId === agentId
                ).stats;
                setAgentTimeInStateData(stats);
            }
            setChartsRange(enumerateRangeBetweenDates(selectedDates[0], selectedDates[1]));
        });
        setUiState(UISTATES.FINISHED);
    };

    const loadTagChartData = (tag) => {
        if (!tag) {
            setUiState(UISTATES.FINISHED);
            return;
        }
        setUiState(UISTATES.LOADING);
        api.reports()
            .tags(
                selectedDates[0].format("YYYY-MM-DD"),
                selectedDates[1].format("YYYY-MM-DD"),
                rangeType.toUpperCase() + "S",
                tag,
                dateField
            )
            .then((res) => {
                if (res.data.knowledgeRecordTagsStats.length > 0) {
                    setTagsDataPoints(res.data.knowledgeRecordTagsStats[0].dataPoints);
                } else {
                    setTagsDataPoints([]);
                }
                setChartsRange(enumerateRangeBetweenDates(selectedDates[0], selectedDates[1]));
                setUiState(UISTATES.FINISHED);
            });
    };

    const loadAnswersChartData = (kr_ids) => {
        if (!kr_ids) {
            setUiState(UISTATES.FINISHED);
            return;
        }
        setUiState(UISTATES.LOADING);
        api.reports()
            .answers(
                selectedDates[0].format("YYYY-MM-DD"),
                selectedDates[1].format("YYYY-MM-DD"),
                rangeType.toUpperCase() + "S",
                kr_ids,
                dateField
            )
            .then((res) => {
                setAnswerDataPoints(res.data);
                setChartsRange(enumerateRangeBetweenDates(selectedDates[0], selectedDates[1]));
                setUiState(UISTATES.FINISHED);
            });
    };

    const loadLabelsChartData = (cl_ids) => {
        if (!cl_ids) {
            setUiState(UISTATES.FINISHED);
            return;
        }
        setUiState(UISTATES.LOADING);
        api.reports()
            .labels(
                selectedDates[0].format("YYYY-MM-DD"),
                selectedDates[1].format("YYYY-MM-DD"),
                rangeType.toUpperCase() + "S",
                cl_ids,
                dateField
            )
            .then((res) => {
                res.data.conversationLabelsStats.forEach((conversationTag) => {
                    conversationTag.dataPoints.forEach((datapoint) => {
                        datapoint.meanDwellTime = (datapoint.meanDwellTime / 60).toFixed(2);
                        datapoint.meanInOfficeHoursDwellTime = (
                            datapoint.meanInOfficeHoursDwellTime / 60
                        ).toFixed(2);
                        return datapoint;
                    });
                });
                setLabelsDataPoints(res.data);
                setChartsRange(enumerateRangeBetweenDates(selectedDates[0], selectedDates[1]));
                setUiState(UISTATES.FINISHED);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const enumerateRangeBetweenDates = function (startDate, endDate) {
        let now = startDate.clone();
        let dates = [];
        if (rangeType !== "day") {
            now = now.startOf(rangeType);
        }
        while (now.isSameOrBefore(endDate)) {
            dates.push(now.utc().format("DD/MM/YYYY"));
            now.add(1, `${rangeType}s`);
        }
        return dates;
    };

    function changeAnswerPage() {
        setAnswerPage((answerPage) => answerPage + 1);
    }

    function changeUsersPage() {
        setUsersPaginatorPage((usersPaginatorPage) => usersPaginatorPage + 1);
    }

    function downloadFromBlob(blob, fileName) {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }

    function exportReportButtonClick() {
        if (!selectedDates || !selectedDates[0] || !selectedDates[1] || !rangeType) {
            NotificationManager.error(t("reports.startDateError"));
            return;
        }
        if (selectedDates[1].isBefore(selectedDates[0])) {
            NotificationManager.error(t("reports.startDateBeforeError"));
            return;
        }
        setUiState(UISTATES.LOADING);
        api.reports()
            .export(
                selectedDates[0].format("YYYY-MM-DD"),
                selectedDates[1].format("YYYY-MM-DD"),
                rangeType.toUpperCase() + "S",
                dateField
            )
            .then((response) => {
                const fileName = response.headers["content-disposition"]
                    .split("filename=")[1]
                    .replaceAll('"', "");
                downloadFromBlob(new Blob([response.data]), fileName);
                setUiState(UISTATES.FINISHED);
            })
            .catch((error) => {
                setUiState(UISTATES.FINISHED);
                message.error(t("reports.export"));
            });
    }

    function handleAnswerModalVisibleOk() {
        setSelectedAnswersToCharts(modalSelectedAnswers);
        setAnswerModalVisible(false);
    }

    function openAnswersModal() {
        setAnswerModalVisible(true);
    }

    function handleAnswerModalVisibleCancel() {
        setAnswerModalVisible(false);
    }

    function handleLabelsModalVisibleOk() {
        setSelectedLabelsToCharts(modalSelectedLabels);
        setLabelsModalVisible(false);
    }

    function openLabelsModal() {
        setLabelsModalVisible(true);
        setModalSelectedLabels([]);
    }

    function handleLabelsModalVisibleCancel() {
        setLabelsModalVisible(false);
        setSearchLabelsInput("");
    }

    const handleKeyWords = function (values) {
        const keywords = [];
        values.forEach((value) => {
            keywords.push({
                id: value,
                text: value,
            });
        });
        setSearchKeyWords(keywords);
    };

    function handleLabelSearch(e) {
        setSearchLabelsInput(e.target.value);
        const filtered = labels.filter((label) =>
            label.value.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setLabelsToTable(filtered);
    }

    const parseFilteredKnowledge = function (list) {
        return list.map((knowledge, index) => {
            const summary = knowledge.answer.slice(0, 200) + " ...";
            const title = knowledge.knowledgeRecord.title;
            return {
                id: knowledge.knowledgeRecord.id,
                title: title,
                answer: { summary: summary },
            };
        });
    };

    function addModalAnswerSelected(selected) {
        let modalSelected = [...modalSelectedAnswers];
        let isAlreadySelected = modalSelectedAnswers.some((item) => item.id === selected.id);
        if (!isAlreadySelected) {
            modalSelected.push(selected);
        }
        setModalSelectedAnswers(modalSelected);
    }

    function removeModalAnswerSelected(selected) {
        let modalSelected = modalSelectedAnswers.filter((item) => item.id !== selected.id);
        setModalSelectedAnswers(modalSelected);
    }

    function addModalLabelsSelected(selected) {
        let modalSelected = [...modalSelectedLabels];
        let isAlreadySelected = modalSelectedLabels.some((item) => item.id === selected.id);
        if (!isAlreadySelected) {
            modalSelected.push(selected);
        }
        setModalSelectedLabels(modalSelected);
    }

    function removeModalLabelsSelected(selected) {
        let modalSelected = modalSelectedLabels.filter((item) => item.id !== selected.id);
        setModalSelectedLabels(modalSelected);
    }

    const columns = [
        {
            title: t("title"),
            dataIndex: "title",
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    {modalSelectedAnswers.find((item) => item.id === record.id) ? (
                        <Button type="link" onClick={() => removeModalAnswerSelected(record)}>
                            {t("remove")}
                        </Button>
                    ) : (
                        <Button type="link" onClick={() => addModalAnswerSelected(record)}>
                            {t("add")}
                        </Button>
                    )}
                </Space>
            ),
        },
    ];

    const labelColumns = [
        {
            title: t("title"),
            dataIndex: "value",
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    {modalSelectedLabels.find((item) => item.id === record.id) ? (
                        <Button type="link" onClick={() => removeModalLabelsSelected(record)}>
                            {t("remove")}
                        </Button>
                    ) : (
                        <Button type="link" onClick={() => addModalLabelsSelected(record)}>
                            {t("add")}
                        </Button>
                    )}
                </Space>
            ),
        },
    ];

    useEffect(() => {
        if (labelsModalVisible) {
            setModalSelectedLabels(selectedLabelsToCharts);
        }
    }, [labelsModalVisible]);

    useEffect(() => {
        if (selectedDates) {
            setUiState(UISTATES.LOADING);
            if (activeTab === "general") {
                api.reports()
                    .workspace(
                        selectedDates[0].format("YYYY-MM-DD"),
                        selectedDates[1].format("YYYY-MM-DD"),
                        rangeType.toUpperCase() + "S",
                        dateField
                    )
                    .then((res) => {
                        setChatsDataPoints(res.data.dataPoints);
                        setChartsRange(
                            enumerateRangeBetweenDates(selectedDates[0], selectedDates[1])
                        );
                        setUiState(UISTATES.FINISHED);
                    });
                api.reports()
                    .interactions(
                        selectedDates[0].format("YYYY-MM-DD"),
                        selectedDates[1].format("YYYY-MM-DD"),
                        rangeType.toUpperCase() + "S",
                        dateField
                    )
                    .then((res) => {
                        setTimeInteractionsDataPoints(res.data.dataPoints);
                    });
            }
            if (activeTab === "agents") {
                loadAgentChartData(selectedUser);
            }
            if (activeTab === "tags") {
                loadTagChartData(selectedTag);
            }
            if (activeTab === "answers") {
                let kr_ids = selectedAnswersToCharts.map((selected) => selected.id);
                loadAnswersChartData(kr_ids);
            }
            if (activeTab === "labels") {
                let cl_ids = selectedLabelsToCharts.map((selected) => selected.id);
                loadLabelsChartData(cl_ids);
            }
        } else {
            setUiState(UISTATES.FINISHED);
        }
    }, [selectedDates, rangeType, dateField]);

    useEffect(() => {
        setIsLoading(true);
        api.users()
            .list(usersPaginatorPage, 32)
            .then((res) => {
                const newUsers = [...users, ...res.data.users];
                setUsers(newUsers);
                setUsersIdToAgentIdMap(
                    Object.fromEntries(newUsers.map((user) => [user.id, user.agentId]))
                );
                setUsersPaginatorHasNext(res.data.paginator.hasNext);
                setIsLoading(false);
            });
    }, [usersPaginatorPage]);

    useEffect(() => {
        if (answerPage === 0) {
            return;
        }
        setIsLoading(true);
        api.knowledge()
            .list(answerPage)
            .then((res) => {
                setAnswerPaginatorHasNext(res.data.paginator.hasNext);
                setAnswers([...answers, ...res.data.knowledgeRecords]);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }, [answerPage]);

    useEffect(() => {
        setUiState(UISTATES.LOADING);
        if (searchKeyWords.length) {
            let searchData = searchKeyWords.join(' ')
            setIsLoading(true);
            api.assistant()
                .get(appContext.state.workspaceId, searchData)
                .then((response) => {
                    let krs = response.data;
                    let mapped_krs = parseFilteredKnowledge(krs);
                    setAnswerPaginatorHasNext(false);
                    setAnswerPage(0);
                    setAnswers([...mapped_krs]);
                })
                .then(() => setIsLoading(false));
        } else {
            api.knowledge()
                .list(answerPage)
                .then((res) => {
                    setUiState(UISTATES.FINISHED);
                    setAnswerPaginatorHasNext(res.data.paginator.nextPage);
                    setAnswers(res.data.knowledgeRecords);
                    setAnswerPage(0);
                })
                .catch((err) => {
                    setUiState(UISTATES.FINISHED);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKeyWords]);

    useEffect(() => {
        api.users()
            .list(0, 32)
            .then((res) => {
                setUsers(res.data.users);
                setUsersIdToAgentIdMap(
                    Object.fromEntries(res.data.users.map((user) => [user.id, user.agentId]))
                );
            });
        api.knowledge()
            .getTags()
            .then((res) => {
                setTags(res.data.tags);
            });
        api.tags()
            .list()
            .then((res) => {
                setLabels(res.data.conversationLabels);
                setLabelsToTable(res.data.conversationLabels);
            });
        api.knowledge()
            .list(answerPage)
            .then((res) => {
                setAnswerPaginatorHasNext(res.data.paginator.hasNext);
                setAnswers(res.data.knowledgeRecords);
            });
    }, []);

    useEffect(() => {
        let kr_ids = selectedAnswersToCharts.map((selected) => selected.id);
        loadAnswersChartData(kr_ids);
    }, [selectedAnswersToCharts]);

    useEffect(() => {
        let cl_ids = selectedLabelsToCharts.map((selected) => selected.id);
        loadLabelsChartData(cl_ids);
    }, [selectedLabelsToCharts]);

    return (
        <div className="opt-view">
            <Loader isLoading={isLoading} />
            <Spin spinning={uiState === UISTATES.LOADING}>
                <Card
                    title={t("reports.cardTitle")}
                    tabList={TranslatedTabList()}
                    activeTabKey={activeTab}
                    onTabChange={(key) => handleTab(key)}
                    extra={
                        <div style={{ position: "relative" }}>
                            <Space>
                                {activeTab === "agents" && (
                                    <Select
                                        key="1"
                                        placeholder={t("reports.agentSelectPlaceholder")}
                                        onChange={handleUserChange}
                                        value={selectedUser}
                                        style={{ minWidth: 200 }}
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                {usersPaginatorHasNext && (
                                                    <LoadMore
                                                        onClick={changeUsersPage}
                                                        style={{
                                                            marginTop: "0px",
                                                            marginBottom: "15px",
                                                        }}
                                                    />
                                                )}
                                            </>
                                        )}
                                    >
                                        {users.map((user) => (
                                            <Select.Option value={user.id}>
                                                {user.email}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                                {activeTab === "tags" && (
                                    <Select
                                        key="2"
                                        placeholder={t("reports.tagSelectPlaceholder")}
                                        onChange={handleTagChange}
                                        style={{ minWidth: 200 }}
                                        value={selectedTag}
                                    >
                                        {tags.map((tag) => (
                                            <Select.Option value={tag.id}>
                                                {tag.value}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                                {activeTab === "answers" && (
                                    <>
                                        <Button onClick={openAnswersModal}>
                                            {t("reports.modalAnswerTitle")}
                                        </Button>
                                        <Modal
                                            title={t("reports.modalAnswerTitle")}
                                            visible={answerModalVisible}
                                            onOk={handleAnswerModalVisibleOk}
                                            onCancel={handleAnswerModalVisibleCancel}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                }}
                                            >
                                                <Button
                                                    danger
                                                    onClick={() => setModalSelectedAnswers([])}
                                                >
                                                    {t("clear")}
                                                </Button>
                                            </div>
                                            <div style={{ overflow: "auto" }}>
                                                {modalSelectedAnswers.map((item) => (
                                                    <Tag
                                                        color="blue"
                                                        onClick={() =>
                                                            removeModalAnswerSelected(item)
                                                        }
                                                        key={item.id}
                                                    >
                                                        {item.title} x
                                                    </Tag>
                                                ))}
                                            </div>
                                            <label className="opt-label-title">
                                                {t("reports.keywords")}
                                            </label>
                                            <Select
                                                size="large"
                                                mode="tags"
                                                dropdownStyle={{ display: "none" }}
                                                style={{ width: "100%" }}
                                                placeholder={t("reports.searchByKeywords")}
                                                onChange={handleKeyWords}
                                            >
                                                {searchKeyWords.map((tag, index) => {
                                                    return (
                                                        <Select.Option
                                                            key={index}
                                                            value={tag.value}
                                                        >
                                                            {tag.value}
                                                        </Select.Option>
                                                    );
                                                })}
                                            </Select>
                                            <Table
                                                rowKey={"id"}
                                                columns={columns}
                                                dataSource={answers}
                                                pagination={false}
                                            />
                                            {answerPaginatorHasNext && (
                                                <LoadMore
                                                    onClick={changeAnswerPage}
                                                    style={{
                                                        marginTop: "0px",
                                                        marginBottom: "15px",
                                                    }}
                                                />
                                            )}
                                        </Modal>
                                    </>
                                )}
                                {activeTab === "labels" && (
                                    <>
                                        <Button onClick={openLabelsModal}>
                                            {t("reports.selectConversationTags")}
                                        </Button>
                                        <Modal
                                            title={t("reports.conversationTags")}
                                            visible={labelsModalVisible}
                                            onOk={handleLabelsModalVisibleOk}
                                            onCancel={handleLabelsModalVisibleCancel}
                                            style={{ overflow: "hidden", maxHeight: "700px" }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <label className="opt-label-title">
                                                    {t("selected")}:
                                                </label>
                                                <Button
                                                    danger
                                                    onClick={() => setModalSelectedLabels([])}
                                                >
                                                    {t("selected")}
                                                </Button>
                                            </div>
                                            <div style={{ overflow: "auto", maxHeight: "100px" }}>
                                                {modalSelectedLabels.map((item) => (
                                                    <Tag
                                                        color="blue"
                                                        onClick={() =>
                                                            removeModalLabelsSelected(item)
                                                        }
                                                    >
                                                        {item.value} x
                                                    </Tag>
                                                ))}
                                            </div>
                                            <label className="opt-label-title">{t("search")}</label>
                                            <Input
                                                value={searchLabelsInput}
                                                onChange={handleLabelSearch}
                                            />
                                            <Table
                                                rowKey={"id"}
                                                columns={labelColumns}
                                                dataSource={labelsToTable}
                                                pagination={false}
                                                scroll={{ y: 230 }}
                                            />
                                        </Modal>
                                    </>
                                )}
                                <Select value={rangeType} onChange={handleRangeChange}>
                                    <Select.Option value="day">{t("day")}</Select.Option>
                                    <Select.Option value="month">{t("month")}</Select.Option>
                                    <Select.Option value="year">{t("year")}</Select.Option>
                                </Select>
                                <Select defaultValue="CREATED_ON" onChange={handleDateFieldChange}>
                                    <Select.Option value="CREATED_ON">
                                        {t("reports.useCreationTime")}
                                    </Select.Option>
                                    <Select.Option value="CLOSED_ON">
                                        {t("reports.useClosingTime")}
                                    </Select.Option>
                                </Select>
                                <RangePicker
                                    picker={rangeType}
                                    value={selectedDates}
                                    onChange={handleDateChange}
                                    allowClear
                                    allowEmpty
                                />
                                <Button
                                    onClick={exportReportButtonClick}
                                    disabled={
                                        !selectedDates ||
                                        !selectedDates[0] ||
                                        !selectedDates[1] ||
                                        !rangeType
                                    }
                                >
                                    {t("reports.export")}
                                </Button>
                            </Space>
                            {(activeTab === "general" ||
                                activeTab === "agents" ||
                                activeTab === "tags") && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "50px",
                                        right: "0",
                                        zIndex: 9,
                                    }}
                                >
                                    <Radio.Group value={layoutType} onChange={handleLayout}>
                                        <Radio.Button value="grid">
                                            <AppstoreOutlined />
                                        </Radio.Button>
                                        <Radio.Button value="list">
                                            <BarsOutlined />
                                        </Radio.Button>
                                    </Radio.Group>
                                </div>
                            )}
                        </div>
                    }
                >
                    {renderTab(activeTab)}
                </Card>
            </Spin>
        </div>
    );
}
export default Reports;
